@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ProximaNovaA";
  src: url("./assets/fonts/ProximaNovaA-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNovaA";
  src: url("./assets/fonts/ProximaNovaA-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "ProximaNovaA";
  src: url("./assets/fonts/ProximaNovaA-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "ProximaNovaA";
  src: url("./assets/fonts/ProximaNovaA-Thin.ttf");
  font-weight: 200;
}

/* @font-face {
  font-family: "ProximaNovaA";
  src: url("./assets/fonts/ProximaNovaA-Medium.ttf");
  font-weight: 500;
} */

@font-face {
  font-family: "ProximaNovaA";
  src: url("./assets/fonts/ProximaNovaA-Semibold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "ProximaNovaA";
  src: url("./assets/fonts/ProximaNovaA-Extrabld.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "ProximaNovaA";
  src: url("./assets/fonts/ProximaNovaA-Black.ttf");
  font-weight: 900;
}

body {
  position: relative;
  background-color: #fbfafd;
}
